<template>
  <div class="mt-5 px-2">
    <article class="message is-danger" v-if="!token">
      <div class="message-body">
        You have not signed in yet. Please go back to <a href="/">home page</a> to sign in first.
      </div>
    </article>
    <div v-if="token">
      <div>
        <span class="is-pulled-right">
          <a class="button mr-2" @click="exportThisPage">Export</a>
        </span>

        <div>
          <h1 class="title my-title">Canada Forecast</h1>&nbsp;
          <div class="select">
            <select v-model="selectedDate">
              <option v-for="(o, i) in dateOptions" :key="'date-option-' +i" :value="o.value">{{o.label}}</option>
            </select>
          </div>
        </div>

        <div class="mt-3 my-overflow">
          <div v-if="waiting">
            <span class="icon is-medium is-size-4">
              <i class="fas fa-spinner fa-pulse"></i>
            </span>
          </div>
          <div v-else>
            <div class="columns mt-4">
              <div class="column field mb-0 pb-0">
                <p class="control has-icons-left">
                  <span class="select">
                    <select v-model="filter">
                      <option v-for="(c, i) in classOptions"  :key="'product-class-option' + i">
                        {{c}}
                      </option>
                    </select>
                  </span>
                  <span class="icon is-small is-left">
                    <i class="fas fa-filter"></i>
                  </span>
                </p>
              </div>
              <div class="column field  mb-0 pb-0">
                <p class="control has-icons-left">
                  <input class="input" type="text" placeholder="Search" v-model="search">
                  <span class="icon is-small is-left">
                    <i class="fas fa-search"></i>
                  </span>
                </p>
              </div>
            </div>

            <div class="field is-grouped">
              <p class="control">
                <span class="select">
                  <select v-model="periods">
                    <option v-bind:value="2">Ship in 90 days</option>
                    <option v-bind:value="3">Ship in 135 days</option>
                    <option v-bind:value="4">Ship in 180 days</option>
                    <option v-bind:value="5">Ship in 225 days</option>
                    <option v-bind:value="6">Ship in 270 days</option>
                    <option v-bind:value="7">Ship in 315 days</option>
                    <option v-bind:value="8">Ship in 360 days</option>
                  </select>
                </span>
              </p>
            </div>

            <div class="table-container">
            <table class="table is-fullwidth is-hoverable is-striped">
              <thead>
                <th class="is-clickable" @click="changeSortOption('idIndex')">
                  <span>Id Index</span>
                  <span class="icon" v-if="sortOption.field == 'idIndex'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">Id</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('nsName')">
                  <span>Product</span>
                  <span class="icon" v-if="sortOption.field == 'nsName'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">Class</span>
                </th>
                <th class="is-clickable" @click="changeSortOption('saleSpeed.value')">
                  <span>Sale Speed</span>
                  <span class="icon" v-if="sortOption.field == 'saleSpeed.value'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" @click="changeSortOption('inventory.ON')">
                  <span>Inventory</span>
                  <span class="icon" v-if="sortOption.field == 'inventory.ON'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" @click="changeSortOption('daysToSell.inventory.days')">
                  <span>Days to sell inventory</span>
                  <span class="icon" v-if="sortOption.field == 'daysToSell.inventory.days'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" @click="changeSortOption('inbounds.ON')">
                  <span>Inbound</span>
                  <span class="icon" v-if="sortOption.field == 'inbounds.ON'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" @click="changeSortOption('daysToSell.inventoryInbounds.days')">
                  <span>Days to sell inventory + inbound</span>
                  <span class="icon" v-if="sortOption.field == 'daysToSell.inventoryInbounds.days'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" @click="changeSortOption('openPos.ON')">
                  <span>Open POs</span>
                  <span class="icon" v-if="sortOption.field == 'openPos.ON'">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                </th>
                <th class="is-clickable" v-for="(h, j) in shipHeaders" :key="'ship-header-' + j" @click="changeSortOption(h.field)">
                  <span>{{h.name}}</span>
                  <span class="icon" v-if="sortOption.field == h.field">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">{{h.date}}</span>
                </th>
                <th class="is-clickable" v-for="(h, j) in shipHeaders" :key="'increase-pos-header-' + j" @click="changeSortOption(h.field1)">
                  <span>{{h.name1}}</span>
                  <span class="icon" v-if="sortOption.field == h.field1">
                    <i class="fas" :class="{'fa-sort-up': sortOption.asc, 'fa-sort-down': !sortOption.asc}"></i>
                  </span>
                  <br/>
                  <span class="is-size-7 has-text-grey">{{h.date}}</span>
                </th>
              </thead>
              <tbody>
                <tr v-for="(p, i) in showingProducts" :key="'product-' + i">
                  <td>
                    <span>{{p.idIndex}}</span><br/>
                    <span class="is-size-7 has-text-grey">{{p.id}}</span>
                  </td>
                  <td>
                    <span>{{p.nsName}}</span><br/>
                    <span class="is-size-7 has-text-grey">{{p.className}}</span>
                  </td>
                  <td>
                    <span>{{p.saleSpeed.label}}</span>
                  </td>
                  <td>
                    <span>{{p.inventory.ON}}</span>
                  </td>
                  <td>
                    <span v-if="p.daysToSell.inventory.days != undefined">{{p.daysToSell.inventory.days}}</span><br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.daysToSell.inventory.date}}</span>
                    </span>
                  </td>
                  <td>
                    <span>{{p.inbounds.ON}}</span>
                  </td>
                  <td>
                    <span v-if="p.daysToSell.inventoryInbounds.days != undefined">{{p.daysToSell.inventoryInbounds.days}}</span><br/>
                    <span class="is-size-7 has-text-grey">
                      <span>{{p.daysToSell.inventoryInbounds.date}}</span>
                    </span>
                  </td>
                  <td>
                    <span>{{p.openPos.ON}}</span>
                  </td>
                  <td v-for="(h, j) in shipHeaders" :key="'ship-value-' + i + '-' + j">
                    <span v-if="p.ships[j] != undefined">{{p.ships[j]}}</span>
                  </td>
                  <td v-for="(h, j) in shipHeaders" :key="'increase-pos-value-' + i + '-' + j">
                    <span v-if="p.increasePos[j] != undefined">{{p.increasePos[j]}}</span><br/>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>
      
      
      <div v-if="error" class="notification is-danger is-light">
        <button class="delete" @click="error=''"></button>
        {{error}}
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from 'dateformat'
import { saveAs } from 'file-saver'

function getFieldValue (obj, path) {

  var ss = path.split('.')
  var value = obj[ss[0]]
  var i = 1
  while (i < ss.length && value) {
    value = value[ss[i]]
    i += 1
  }
  return value
}

export default {
  name: 'ForecastCanada',
  components: {
  },
  data () {
    return {
      error: '',
      waiting: false,
      search: '',
      filter: 'All',
      products: [],
      classOptions: [],
      search: '',
      sortOption: { field: 'id', asc: true },
      periods: 5,
      shippingDays: {},
      selectedDate: null,
      dateOptions: [
        {label: 'Now', value: null}
      ],
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    token () {
      return this.$store.state.user.token
    },
    todayDate () {
      if (this.selectedDate) {
        return new Date(this.selectedDate)
      }
      return new Date()
    },
    shipHeaders () {
      var headers = []
      var period = 1
      var now = this.todayDate
      while(period <= this.periods) {
        var days = 45 * period
        var date = new Date(now.getTime() + (days * 86400000))
        headers.push({
          name: 'Ship in ' + days + ' days',
          date: dateFormat(date, 'yyyy-mm-dd'),
          field: 'ships.' + (period - 1),
          name1: 'increase POs ship-in-' + days + '-days',
          field1: 'increasePos.' + (period - 1),
        })
        period += 1
      }
      return headers
    },
    showingProducts () {
      var vm = this
      var filteredProducts = this.products.filter(p => {
        if (vm.filter == 'All') {
          return true
        }
        return p.className == vm.filter
      }).filter(p => {
        var search = vm.search.toLowerCase()
        return p.id.toString().toLowerCase().includes(search)
          || p.model.toLowerCase().includes(search)
          || p.nsName.toLowerCase().includes(search)
          || p.className.toLowerCase().includes(search)
          || p.idIndex.toLowerCase().includes(search)
      })

      var computedProducts = filteredProducts.map(p => vm.computeProduct(p))

      var sort = vm.sortOption
      var sortedProducts = computedProducts.sort((a, b) => {
        var va = getFieldValue(a, sort.field)
        var vb = getFieldValue(b, sort.field)
        if (!va || !vb) {
          if (va) {
            return sort.asc ? 1 : -1
          }
          if (vb) {
            return sort.asc ? -1 : 1
          }
          return 0
        }
        if (sort.field == 'id' || sort.field == 'inventory.ON' || sort.field == 'saleSpeed.value'
          || sort.field == 'inbounds.ON'|| sort.field == 'openPos.ON' || sort.field.startsWith('ships.')
          || sort.field.startsWith('increasePos.') || sort.field.startsWith('daysToSell.')) {
          return sort.asc ? va - vb : vb - va
        }
        return sort.asc ? va.localeCompare(vb) : vb.localeCompare(va)
      })
      return sortedProducts
    },
  },
  watch: {
    selectedDate: function (val) {
      if (val) {
        this.getScreenshot(val)
      } else {
        this.getProducts()
      }
    },
  },
  methods: {
    getProducts () {
      this.waiting = true
      this.$http.get(this.server + '/myapp/get-products-for-forecast/').then(resp => {
        this.getProductsFromResp(resp)
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    getProductsFromResp (resp) {
      this.products = resp.body.products.map(p => this.makeProduct(p, resp.body.shippingDays))
      this.shippingDays = resp.body.shippingDays
      var classNames = new Set()
      this.products.forEach(p => classNames.add(p.className))
      this.classOptions = [...classNames]
      this.classOptions.sort((a, b) => a.localeCompare(b))
      this.classOptions.unshift('All')
    },
    makeProduct (p, shippingDays) {
      return {
        id: p.product.id,
        model: p.product.model,
        nsName: p.product.nsName,
        className: p.product.productClass,
        idIndex: p.product.nsIdIndex,
        inventory: this.makeInventory(p.inventory),
        saleSpeed: this.getSaleSpeed(p.product),
        inbounds: this.makeInbounds(p.inventory),
        openPos: this.makeOpenPos(p.inventory),
        forecastParams: this.getForecastParams(p.product, shippingDays),
      }
    },
    makeInventory (inventory) {
      var onInventory = inventory.onInventory || 0
      return {
        ON: onInventory,
      }
    },
    getSaleSpeed (p) {
      var salesData = p.salesData ? JSON.parse(p.salesData) : {}
      var saleSpeed = salesData.saleSpeedCanada || 0
      var saleSpeedLabel = saleSpeed ? this.roundNum(saleSpeed) : '0'
      return {value: saleSpeed, label: saleSpeedLabel}
    },
    makeInbounds (inventory) {
      var inbounds = inventory.inbounds ? JSON.parse(inventory.inbounds) : {}
      inbounds.ON = inbounds.ON ? parseInt(inbounds.ON) : 0
      return inbounds
    },
    makeOpenPos (inventory) {
      var openPos = inventory.openPos ? JSON.parse(inventory.openPos) : {}
      openPos.ON = openPos.ON ? parseInt(openPos.ON) : 0
      return openPos
    },
    getForecastParams (p, shippingDays) {
      var forecastParams = p.forecastParams ? JSON.parse(p.forecastParams) : {}
      if (!forecastParams.daysToStock) {
        forecastParams.daysToStock = 45
      }
      if (!forecastParams.factoryPrepDays) {
        forecastParams.factoryPrepDays = 45
      }
      if (shippingDays) {
        forecastParams.shippingDays = shippingDays.Canada
      }
      if (!forecastParams.shippingDays) {
        forecastParams.shippingDays = 55
      }
      return forecastParams
    },
    computeProduct (p) {
      var daysToSell = this.computeDayToSell(p)
      var ships = this.computeShips(p)
      var increasePos = this.computeIncreasePos(p, ships)
      return {...p, daysToSell: daysToSell, ships: ships, increasePos: increasePos}
    },
    computeDayToSell (p) {
      var daysToSell = {inventory: {}, inventoryInbounds: {}}
      var speed = p.saleSpeed.value
      if (!speed) {
        return daysToSell
      }
      var inventory = p.inventory.ON
      var inbounds = p.inbounds.ON
      var now = this.todayDate
      var daysToSellInventoryDays = Math.round(inventory / speed)
      var daysToSellInventoryDate = new Date(now.getTime() + (daysToSellInventoryDays * 86400000))
      daysToSell['inventory'] = {days: daysToSellInventoryDays, date: dateFormat(daysToSellInventoryDate, 'yyyy-mm-dd')}
      var daysToSellInventoryInboundsDays = Math.round((inventory + inbounds) / speed)
      var daysToSellInventoryInboundsDate = new Date(now.getTime() + (daysToSellInventoryInboundsDays * 86400000))
      daysToSell['inventoryInbounds'] = {days: daysToSellInventoryInboundsDays, date: dateFormat(daysToSellInventoryInboundsDate, 'yyyy-mm-dd')}
      return daysToSell
    },
    computeShips (p) {
      var ships = []
      var period = 1
      var sum = 0
      var speed = p.saleSpeed.value
      var shippingDays = p.forecastParams.shippingDays
      var daysToStock = p.forecastParams.daysToStock
      var factoryPrepDays = p.forecastParams.factoryPrepDays
      var inventory = p.inventory.ON
      var inbounds = p.inbounds.ON
      while (period <= this.periods) {
        var v1 = inventory + inbounds + sum - (speed * shippingDays * period)
        var m1 = Math.max(v1, 0)
        var v2 = speed * (daysToStock + factoryPrepDays) - m1
        var m2 = Math.max(v2, 0)
        ships.push(Math.round(m2))
        sum += m2
        period += 1
      }
      return ships
    },
    computeIncreasePos (p, ships) {
      var openPos = p.openPos.ON
      var sum = 0
      var increasePos = []
      for (var ship of ships) {
        var m1 = Math.max(openPos - sum, 0)
        var m2 = Math.max(ship - m1, 0)
        increasePos.push(Math.round(m2))
        sum += ship
      }
      return increasePos
    },
    roundNum (n) {
      if (n >= 10) {
        return Math.round(n)
      }
      if (n >= 1) {
        return Math.round(n * 10) / 10
      }
      return Math.round(n * 100) / 100
    },
    changeSortOption (field) {
      this.sortOption = {
        field: field,
        asc: field == this.sortOption.field ? !this.sortOption.asc : true
      }
    },
    exportThisPage () {
      var headers = ['id', 'idIndex', 'nsName', 'className', 'saleSpeed.value', 
        'inventory.ON', 'daysToSell.inventory.days', 'daysToSell.inventory.date',
        'inbounds.ON', 'daysToSell.inventoryInbounds.days', 'daysToSell.inventoryInbounds.date', 
        'openPos.ON',
        'forecastParams.daysToStock', 'forecastParams.factoryPrepDays', 'forecastParams.shippingDays',
      ]
      for (var h of this.shipHeaders) {
        headers.push({label: h.name + ': ' + h.date, value: h.field})
      }
      for (var i=0;i<this.shipHeaders.length;i++) {
        var h = this.shipHeaders[i]
        headers.push({label: h.name1 + ': ' + h.date, value: h.field1})
      }
      var rows = this.showingProducts.map(p => headers.map (h => (h.value ? (getFieldValue(p, h.value) ? getFieldValue(p, h.value) : '') : (getFieldValue(p, h) ? getFieldValue(p, h) : ''))).join('\t'))
      rows.unshift(headers.map(h => h.label ? h.label : h).join('\t'))
      var string = rows.join('\r\n')
      var blob = new Blob([string], {type: 'text/plain;charset=utf-8'})
      var today = dateFormat(this.todayDate, 'isoDate')
      saveAs(blob, 'forecast_canada_' + today + '.tsv')
    },
    getDates () {
      var data = {api: 'get-products-for-forecast'}
      this.$http.post(this.server + '/myapp/find-api-dates/', data).then(resp => {
        var dateOptions = [{label: 'Now', value: null}]
        for (var date of resp.body) {
          dateOptions.push({label: date, value: date})
        }
        this.dateOptions = dateOptions
      }, err => {
        this.error = err.body
      })
    },
    getScreenshot (date) {
      var data = {api: 'get-products-for-forecast', date: date}
      this.$http.post(this.server + '/myapp/find-screenshot/', data).then(resp => {
        this.getProductsFromResp(resp)
      }, err => {
        this.error = err.body
      })
    },
  },
  mounted () {
    this.getProducts()
    this.getDates()
  },
}
</script>

<style scoped>
.my-overflow {
  overflow-x: scroll;
}

.table-container {
  max-height: calc(100vh - 80px);
  overflow: auto;

  table {
    text-align: left;
    position: relative;
  }

  th {
    background: white;
    position: sticky;
    top: 0;
  }
}

.my-title {
  display: inline;
}
</style>
    